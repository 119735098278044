<template>
  <section class="m-merchant-type m-scroll">
    <!-- 头部导航 -->
    <m-header title="Merchants Type"></m-header>
    <van-radio-group v-model="radio" class="type-radio" v-if="status == -1">
      <van-cell-group>
        <van-cell title="By company" clickable @click="radio = '1'" class="type-cell">
          <template #right-icon>
            <van-radio name="1" />
          </template>
        </van-cell>
        <van-cell title="By individual" clickable @click="radio = '2'" class="type-cell">
          <template #right-icon>
            <van-radio name="2" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="footer" v-if="status == -1">
      <van-button @click="onNext" round block type="info"  color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Next</van-button>
    </div>

    <!-- 审核状态 -->
    <div class="merchant-status" v-if="status == 0">
      <img src="@/assets/merchant/empty.png" alt="">
      <h5>Application Processing</h5>
      <p>Application is processing, thanks for your patience. </p>
      <div class="empty-button" @click="$router.push('/en/customer')">Contact customer</div>
    </div>
    <div class="merchant-status" v-if="status == 1">
      <img src="@/assets/payment/success@2x.png" alt="" class="status-img">
      <h5>Congrats! Approved! </h5>
      <p>Your application has been approved! Please login</p>
      <p style="color:#000">https://shop.moobyyoho.com</p>
      <p>on computer for further operation. Username is the application's phone number. </p>
      <p style="color:#F06448">*User can use SMS to login at the first time. </p>
      <div class="color-button" @click="$router.push(`/en/merchant/index/${shop_id}`)">My Store</div>
      <div class="empty-button" @click="updateCompany" v-if="shop_type == 0">Upgrade</div>
    </div>
    <div class="merchant-status" v-if="status == 2">
      <img src="@/assets/payment/fail.png" alt="" class="status-img">
      <h5>Not Approved</h5>
      <p>Your application has not been approved! We will contact you within 7 business days for furthur actions. Thanks! </p>
      <div class="color-button" style="margin-top:50px" @click="reApplyHandle">Re-apply </div>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'

import { shop_status } from '@/api/zh/mine.js'
import { reApply } from '@/api/zh/mine.js'
export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      radio: '',
      status: -1,
      shop_id: 1,
      shop_type: 0
    }
  },
  created() {
    this.getShop()
  },
  methods:{
    // 提交
    onNext(){
      if(this.radio == ""){
        return false
      }else {
        this.$router.push({path: `/en/merchant/application/${this.radio}`})
      }
    },
    reApplyHandle() {
      // 重新申请
      reApply().then(res => {
        if(res) {
          this.$router.push({path: `/en/merchant/application/1`})
        } else {
          this.$notify({ type: 'warning', duration: 2000, message: res.msg })
        }
      })
    },
    updateCompany() {
      // 升级为企业
      this.$router.push({path: `/en/merchant/application/1`})
    },
    // 获取 入驻平台状态
    getShop() {
      this.$store.commit('load')
      shop_status().then(res => {
        if(res) {
          this.status = res.status
          if(this.status == 1) {
            this.shop_id = res.shops_id
            this.shop_type = res.shops_type
          }
        }
      }).finally(() => {
        this.$store.commit('unload')
      })
    },
  }
}
</script>

<style lang="less" scoped>
.m-merchant-type {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  overflow-x: hidden;
  overflow-y: auto;
  .type-radio {
    margin-top: 12px;
  }
  .type-cell {
    height: 70px;
    /deep/ .van-cell__title {
      display: flex;
      align-items: center;;
    }
  }
  .footer {
    margin: 100px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
  }
}
 
.merchant-status{
  img{width:200px;margin:0 auto;display: block;margin-top:80px}
  .status-img{width:120px}
  h5{font-size: 16px;text-align: center;letter-spacing: 1.125px;color: #000000;line-height: 40px;margin-top:20px}
  p{font-size: 14px;color:#888;text-align: center;padding:0 30px;line-height: 21px;}
  .empty-button,.color-button{width:300px;height:42px;border-radius: 42px;border:1px solid #2562B9;font-size: 16px;color:#2562B9;line-height: 42px;
  text-align: center;background-color: #fff;margin:20px auto 0 auto}
  .color-button{border:none;background:linear-gradient(295.9deg, #F03C18 12.2%, #F06448 82.1%);color:#fff}
}
</style>